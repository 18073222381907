// 采购详情
<template>

    <div class='purchaseDetails baseBg'>
        <template v-if="needBackUp">
            <backUp></backUp>
        </template>
        <!-- 头部搜索 -->
        <div class="searchBar ofh">
            <p class="purchaseName">{{detailsInfo.code}}</p>
        </div>

        <!-- 表格 -->
        <el-row :gutter="15">
            <el-col :span="6">
                <div class="cardBox"
                     v-if="detailsInfo.inStatus && detailsInfo.inStatus.name === 'FINISH'">
                    <div class="cardTitle">完成情况：</div>
                    <p><span class="label">完成时间：</span><span class="value">{{detailsInfo.finishTime || '无'}}</span></p>
                    <p><span class="label">采购完成人：</span><span class="value">{{detailsInfo.finishOperatorName || '无'}}</span></p>
                    <p><span class="label">采购数量：</span><span class="value">{{`${detailsInfo.empCount || 0} / ${detailsInfo.inCount || 0} `}}</span></p>
                    <p><span class="label">异常描述：</span><span class="value">{{detailsInfo.errorDescription || '无'}}</span></p>
                </div>
                <div class="cardBox">
                    <div class="cardTitle">采购信息：</div>
                    <p><span class="label">采购编号：</span><span class="value">{{detailsInfo.code || '无'}}</span></p>
                    <p><span class="label">采购单创建时间：</span><span class="value">{{detailsInfo.createTime || '无'}}</span></p>
                    <p><span class="label">采购单创建人：</span><span class="value">{{detailsInfo.addOperatorName || '无'}}</span></p>
                    <p><span class="label">采购日期：</span><span class="value">{{detailsInfo.purchaseDate || '无'}}</span></p>
                    <p><span class="label">采购负责人：</span><span class="value">{{detailsInfo.purchasePersonName || '无'}}</span></p>
                    <p><span class="label">采购部门：</span><span class="value">{{detailsInfo.belongDeptName || '无'}}</span></p>
                    <p><span class="label">关联项目：</span><span class="value">{{detailsInfo.projectName || '无'}}</span></p>
                    <p><span class="label">备注：</span><span class="value textareaInfo">{{detailsInfo.remark || '无'}}</span></p>
                    <p><span class="label">采购依据：</span></p>
                    <div class="fileBox">
                        <com-file-upload-list :showUpBtn="false"
                                              :neddLoadFile="true"
                                              :uploadData="{resourceId: id,resourceType:'purchaseOrder'}"></com-file-upload-list>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <div class="listBox">
                    <div class="tableBox">
                        <el-table :data="tableData.data"
                                  border
                                  stripe>
                            <el-table-column v-for="col in tableData.columns"
                                             :prop="col.id"
                                             :key="col.id"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                            <el-table-column label="操作"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-link type="primary"
                                             @click="details(scope.row)">查看</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>

</template>

<script>
import ComFileUploadList from "../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/purchaseManage.js";
import backUp from "@/components/backUp";
export default {
    name: "purchaseDetails",

    props: [],

    components: {
        ComFileUploadList,
        backUp,
    },

    data() {
        return {
            needBackUp: this.$route.query.needBackUp
                ? JSON.parse(this.$route.query.needBackUp)
                : true, // 是否需要返回上一级
            detailsInfo: "", // 采购详情
            id: this.$route.query.selected,
            tableData: {
                columns: [
                    { id: "empType", label: "设备类型", width: "150" },
                    { id: "provider", label: "供应商", width: "150" },
                    { id: "empTypeModel", label: "规格型号", width: "150" },
                    { id: "purchaseCount", label: "采购数量", width: "150" },
                    { id: "inCount", label: "入库数量", width: "150" },
                    { id: "unitPrice", label: "采购价格（元）", width: "150" },
                ],
                data: [],
            },
        };
    },

    created() {},

    mounted() {
        // 获取采购详情
        this.detailsPurchase();
        // 获取采购详情 采购列表
        this.tableDetailsPurchase();
    },

    methods: {
        // 获取采购详情
        detailsPurchase() {
            let loading = Loading.service({
                target: document.querySelector(".cardBox"),
            });
            API.detailsPurchase(this.$route.query.selected)
                .then((res) => {
                    loading.close();
                    this.detailsInfo = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取采购详情 采购列表
        tableDetailsPurchase() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.tableDetailsPurchase({ orderId: this.$route.query.selected })
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 查看设备信息
        details(item) {
            this.$router.push({
                path: "/projectManage/deviceDetails",
                query: {
                    purchaseViewId: item.id,
                    modelId: item.modelId,
                    deviceName: item.empType,
                    provider: item.provider, // 供应商
                    empTypeModel: item.empTypeModel, // 规格型号
                    count: item.purchaseCount - item.inCount, // 采购数量 - 入库数量
                },
            });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.cardBox p .label {
    width: 125px;
}
.cardBox p .value {
    width: calc(100% - 125px);
}
.fileBox {
    padding: 5px;
    margin-top: -20px;
}
</style>
