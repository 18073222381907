// import request from '@/utils/request'
import {
    get,
    // post,
    postForm,
    postJson,
} from "./http/http";

const httpServer = {
    // 查询采购清单列表
    getPurchaseTable(params) {
        return postJson("/emp/purchase-order/table", params);
    },
    // 保存采购清单
    savePurchase(params) {
        return postJson("/emp/purchase-order/save", params);
    },
    // 删除采购清单
    delPurchase(params) {
        return postForm(`/emp/purchase-order/delete`, params);
    },
    // 采购详情
    detailsPurchase(id) {
        return get(`/emp/purchase-order/findById/${id}`);
    },
    // 采购详情 采购列表
    tableDetailsPurchase(params) {
        return postJson(`/emp/purchase-view/table`, params);
    },
    // 查询实施计划下拉
    taskSelect(id) {
        return get(`/task/task/getSelectList/${id}`);
    },
    // 保存部署实施
    saveDeployment(params) {
        return postJson("/deploy/saveOrUpdate", params);
    },
    // 查询部署详情
    getDeploymentDetails(params) {
        return get("/deploy/findEditInfo", params);
    },
    // 获取设备采购管理
    getPurchseDetails(id) {
        return get(`/emp/purchase-order/detail/${id}`);
    },
};

export default httpServer;
